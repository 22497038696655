import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../App/store';
import { fetch, toggle } from '../../api/notificationSetting';
import { NotificationSettingModel } from 'api/model/notificationSetting';
import { serializeError } from 'serialize-error';

type NotificationSettingLoaded = {
  notificationSettings: NotificationSettingModel[];
};

type NotificationSettingState = {
  notificationSettings: NotificationSettingModel[];
  loading: boolean;
  error: any;
};

type toggleMailFlagLoaded = {
  notificationSetting: NotificationSettingModel;
};

const initialState: NotificationSettingState = {
  notificationSettings: [],
  loading: false,
  error: null,
};

const notificationSetting = createSlice({
  name: 'notificationSetting',
  initialState,
  reducers: {
    fetchNotificationSettingsStart(state) {
      state.loading = true;
    },
    fetchNotificationSettingsSuccess(
      state,
      action: PayloadAction<NotificationSettingLoaded>
    ) {
      const { notificationSettings } = action.payload;
      state.notificationSettings = notificationSettings;
      state.loading = false;
      state.error = null;
    },
    fetchNotificationFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    toggleMailFlagStart(state) {
      state.loading = true;
    },
    toggleMailFlagSuccess(state, action: PayloadAction<toggleMailFlagLoaded>) {
      const { notificationSetting } = action.payload;
      const updateNotification = state.notificationSettings.findIndex(
        (currentNotificationSetting) =>
          currentNotificationSetting.id === Number(notificationSetting.id)
      );
      if (updateNotification !== -1) {
        state.notificationSettings[
          updateNotification
        ].should_send_daily_report_mail =
          notificationSetting.should_send_daily_report_mail;
        state.notificationSettings[
          updateNotification
        ].should_send_appointment_remind_mail =
          notificationSetting.should_send_appointment_remind_mail;
        state.notificationSettings[
          updateNotification
        ].should_send_schedule_mail =
          notificationSetting.should_send_schedule_mail;
        state.notificationSettings[
          updateNotification
        ].should_send_message_mail =
          notificationSetting.should_send_message_mail;
        state.notificationSettings[
          updateNotification
        ].should_send_task_remind_mail =
          notificationSetting.should_send_task_remind_mail;
        state.notificationSettings[updateNotification].should_send_task_mail =
          notificationSetting.should_send_task_mail;
      }

      state.loading = false;
      state.error = null;
    },
    toggleMailFlagFailure(state, action: PayloadAction<Error>) {
      state.error = serializeError(action.payload);
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export const {
  fetchNotificationSettingsSuccess,
  fetchNotificationSettingsStart,
  fetchNotificationFailure,
  toggleMailFlagStart,
  toggleMailFlagSuccess,
  toggleMailFlagFailure,
  resetError,
} = notificationSetting.actions;
export default notificationSetting.reducer;

export const fetchNotificationSettings = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchNotificationSettingsStart());
    const res = await fetch();
    dispatch(
      fetchNotificationSettingsSuccess({
        notificationSettings: res.data.data.user_emails,
      })
    );
  } catch (err: any) {
    dispatch(fetchNotificationFailure(err));
    throw err;
  }
};

export const toggleMailFlag =
  (userEmailId: number, mailType: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(toggleMailFlagStart());
      const res = await toggle({
        userEmailId: userEmailId,
        mailType: mailType,
      });
      dispatch(
        toggleMailFlagSuccess({
          notificationSetting: res.data.data.user_email,
        })
      );
    } catch (err: any) {
      dispatch(toggleMailFlagFailure(err));
      throw err;
    }
  };
